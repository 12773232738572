import { NUMBER_FORMATS } from '~~/types/locale'

export default defineI18nConfig(() => {
  return {
    legacy: false,
    locale: process.env.NUXT_PUBLIC_LOCALE_DEFAULT,
    fallbackLocale: process.env.NUXT_PUBLIC_LOCALE_DEFAULT,
    numberFormats: NUMBER_FORMATS,
  }
})
